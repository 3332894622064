<template>
  <div class="registration">
    <Registration />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Registration from "@/components/public/pages/registration/Registration";

export default {
  name: "LimeLoansRegistrationpage",

  components: { Registration },

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    // if (this.auth.loggedIn) {
    //   this.$router.push({ name: "DashboardMyLoanPage" });
    // }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
