<template>
  <div class="registration-page">
    <div class="registration">
      <!-- Step 1 -->
      <div v-if="getCurrentState() === 'step1'">
        <Step1 />
      </div>

      <!-- Step 2 -->
      <div v-else-if="getCurrentState() === 'step2'">
        <Step2 />
      </div>

      <!-- Step 3 -->
      <div v-else-if="getCurrentState() === 'step3'">
        <Step3 />
      </div>

      <!-- Completed -->
      <div v-else-if="getCurrentState() === 'completed'">
        <Completed />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import Step1 from "@/components/public/pages/registration/Step1";
import Step2 from "@/components/public/pages/registration/Step2";
import Step3 from "@/components/public/pages/registration/Step3";
import Completed from "@/components/public/pages/registration/Completed";

export default {
  name: "LimeLoansRegistration",

  components: { Step1, Step2, Step3, Completed },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth", list: "list", registration: "registration" }),
  },

  created() {
    this.setCurrentState("step1");

    if (this.auth.loggedIn) {
      if (
        this.auth &&
        this.auth.user &&
        this.auth.user.banking_details &&
        this.auth.user.banking_details.bank_name &&
        this.auth.user.banking_details.account_number &&
        this.auth.user.banking_details.bank_name == "Standard Bank" &&
        this.auth.user.banking_details.account_number == "123456789"
      ) {
        this.$router.push({ name: "DashboardMyLoanPage" });
      } else if (
        this.auth.user.has_banking &&
        this.auth.user.has_employment &&
        this.auth.user.has_next_of_kin
      ) {
        this.$router.push({ name: "DashboardMyLoanPage" });
      } else if (
        !this.auth.user.has_banking ||
        !this.auth.user.has_employment
      ) {
        this.setCurrentState("step2");
      } else if (!this.auth.user.has_next_of_kin) {
        this.setCurrentState("step3");
      }
    }

    this.setApiLists();

    // this.setSession();

    this.$helpers.resetErrorHandlerState();
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapGetters({
      getCurrentState: "registration/getCurrentState",
      getSessionExpires: "auth/getSessionExpires",
    }),

    ...mapMutations({
      setCurrentState: "registration/setCurrentState",

      setSessionKey: "auth/setSessionKey",
      setSessionExpires: "auth/setSessionExpires",
      setUser: "auth/setUser",
      setIdNumber: "auth/setIdNumber",

      setListDefaultState: "list/setDefaultState",
      setLists: "list/setLists",
      setSessionExpiresInAnHour: "list/setSessionExpiresInAnHour",
      resetListState: "list/resetState",
    }),

    // setSession
    setSession() {
      if (
        this.getSessionExpires() === "" ||
        this.getSessionExpires() === null
      ) {
        this.logOutUser();
        this.setCurrentState("step1");
      } else {
        var now = this.moment();
        var sessionExpires = this.moment(this.getSessionExpires());
        const isAfter = this.moment(now).isAfter(sessionExpires);

        if (isAfter) {
          // this.setCurrentState("step1");
          // this.logOutUser();
          this.getApiUserDetails();
        }
      }
    },

    // setApiLists
    async setApiLists() {
      // console.log(this.list.sessionExpires);

      if (this.list.sessionExpires != null) {
        var now = this.moment();
        var sessionExpires = this.moment(this.list.sessionExpires);
        const isAfter = this.moment(now).isAfter(sessionExpires);

        if (isAfter) {
          this.setSessionExpiresInAnHour();

          this.resetListState();
          this.setListDefaultState();

          await this.getApiLists();
        }
      } else {
        this.setSessionExpiresInAnHour();

        this.resetListState();
        this.setListDefaultState();

        await this.getApiLists();
      }
    },

    // getApiLists
    async getApiLists() {
      try {
        const res = await this.axios.post("lists");

        if (res.status == 200) {
          const data = res.data;

          // console.log(data);

          this.setLists(data);
        }
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
