<template>
  <div class="completed">
    <v-container class="pa-0 mt-sm-4 mt-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-row>
            <!-- Left -->
            <v-col cols="12" lg="7">
              <h3 class="heading-h3">Thanks for registering</h3>
              <p class="b-body-1 mt-1">
                You have registered on the site now you can submit a request for
                receiving money.
              </p>

              <!-- Apply for loan -->
              <!-- Desktop -->
              <v-btn
                :to="{ name: 'CreateLoanApplicationPage' }"
                class="hidden-xs-only b-button primary text-capitalize"
                height="48"
                width="184"
                elevation="0"
              >
                Apply for loan
              </v-btn>

              <!-- Mobile -->
              <v-btn
                :to="{ name: 'CreateLoanApplicationPage' }"
                class="hidden-sm-and-up b-button primary text-capitalize"
                height="48"
                width="100%"
                elevation="0"
              >
                Apply for loan
              </v-btn>
            </v-col>

            <!-- Right -->
            <v-col cols="12" offset-lg="1" lg="4">
              <v-img
                alt="Completed"
                class="completed"
                contain
                src="@/assets/img/public/pages/registration/completed.svg"
                max-width="288"
              />
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LimeLoansCompleted",

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    if (this.auth.user.negative_classification || !this.auth.user.can_apply) {
      this.$router.push({ name: "DashboardRefusalPage" });
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
